/* 기본 모바일 디자인 */
body {
    max-width: 100%;
    padding: 0;
    margin: 0;
}

/* 스마트폰용 (작은 기기) */
@media screen and (max-width: 375px) {
    .mobile_container {
        width: 100%;
        position: relative;
    }
}

/* 더 큰 스마트폰 또는 작은 태블릿용 */
@media screen and (min-width: 376px) and (max-width: 768px) {
    .mobile_container {
        width: 100%;
        position: relative;
    }
}



.mobile_main_eventBannerSection {
    width: 100%;
    background-color: #d9d9d9;
    overflow: hidden;
    position: relative;
}

.event-banner-slide {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.event-banner {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.indicator-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
}

.indicator {
    width: 10px;
    height: 10px;
    background-color: #9FA5AB;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.indicator.active {
    background-color: #0064FF;
}



.mobile_main_FastFAQSection {
    width: 90%;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.mobile_main_FastFAQSection>h3 {
    margin: 0;
    margin-top: 40px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_FastFAQSection>input {
    margin-bottom: 12px;
    height: 37px;
    border: none;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 0px 10px;
}

.mobile_main_FastFAQSection>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    /* 줄바꿈 없이 한 줄로 표시 */
    margin-bottom: 12px;
}

.mobile_main_FastFAQSection>span>img {
    width: 23px;
    height: 23px;
    cursor: pointer;
}

.mobile_main_FastFAQSection>span>p {
    margin: 0;
    margin-left: 11px;
    font-size: 12px;
    font-weight: 500;
    color: #111;
}

.mobile_main_FastFAQSection>span>p>span {
    color: #767676;
    cursor: pointer;
}

.mobile_main_FastFAQSection>div {
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #0064FF;
    cursor: pointer;
}



.mobile_main_hotDealSection {
    width: 90%;
    margin: auto;
}

.mobile_main_hotDealSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_hotDealSection>button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    color: #767676;
    background-color: transparent;
    cursor: pointer;
}

.mobile_main_hotDealSection>span {
    display: flex;
    flex-direction: row;
    gap: 19px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_main_hotDealSection>span::-webkit-scrollbar {
    display: none;
}

.mobile_hotDealCard {
    min-width: 127px;
    max-width: 127px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.mobile_hotDealCard>div>img {
    width: 55px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.mobile_hotDealCard>img {
    width: 100%;
    background-color: #f5f5f5;
}

.mobile_hotDealCard>h4 {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font: 14px;
    font-weight: 600;
    color: #111;
    white-space: nowrap;
}

.mobile_hotDealCard>p {
    max-height: 28px;
    margin: 0;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_hotDealCard>span:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile_hotDealCard>span:nth-of-type(1)>p:nth-of-type(1) {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_hotDealCard>span:nth-of-type(1):nth-of-type(1)>p:nth-of-type(2) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_hotDealCard>span:nth-of-type(1)>p:nth-of-type(2)>span:nth-of-type(1) {
    font-weight: 400;
    color: tomato;
}

.mobile_hotDealCard>span>p:nth-of-type(2)>span:nth-of-type(2) {
    font-weight: 400;
}

.mobile_hotDealCard>span:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.mobile_hotDealCard>span:nth-of-type(2)>p {
    margin: 0;
    margin-top: 8px;
    padding: 6px 4px;
    background-color: #F0F2F4;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_hotDealCard>button {
    display: block;
    width: 100%;
    height: 30px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #0064FF;
    border: none;
    color: white;
    font-size: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.mobile_hotDealCard>button:hover {
    background-color: blue;
}




.mobile_main_quickDealSection {
    width: 90%;
    margin: auto;
}

.mobile_main_quickDealSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_quickDealSection>button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    color: #767676;
    background-color: transparent;
    cursor: pointer;
}

.mobile_main_quickDealSection>span {
    display: flex;
    flex-direction: row;
    gap: 19px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_main_quickDealSection>span::-webkit-scrollbar {
    display: none;
}


.mobile_quickDealCard {
    min-width: 127px;
    max-width: 127px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.mobile_quickDealCard>div>img {
    width: 70px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.mobile_quickDealCard>img {
    width: 100%;
    background-color: #f5f5f5;
}

.mobile_quickDealCard>h4 {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font: 14px;
    font-weight: 600;
    color: #111;
    white-space: nowrap;
}

.mobile_quickDealCard>p {
    max-height: 28px;
    margin: 0;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_quickDealCard>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
}

.mobile_quickDealCard>span>p:nth-of-type(1) {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    margin-right: 10px;
}

.mobile_quickDealCard>span>p:nth-of-type(2) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: #111;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_quickDealCard>span>p:nth-of-type(2)>span:nth-of-type(1) {
    font-weight: 400;
    color: tomato;
}

.mobile_quickDealCard>span>p:nth-of-type(2)>span:nth-of-type(2) {
    font-weight: 400;
}

.mobile_quickDealCard>span>p:nth-of-type(3) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    color: #0064FF;
}

.mobile_quickDealCard>span:nth-of-type(5) {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.mobile_quickDealCard>span:nth-of-type(5)>p {
    margin: 0;
    padding: 6px 4px;
    background-color: #F0F2F4;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_quickDealCard>button {
    display: block;
    width: 100%;
    height: 30px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #0064FF;
    border: none;
    color: white;
    font-size: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.mobile_quickDealCard>button:hover {
    background-color: blue;
}


.mobile_main_lineBannerSection {
    max-width: 100%;
    height: 150px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
}

.mobile_main_lineBannerSection>img {
    width: 100%;
    margin-top: 30px;
    transform: scale(2.0);
}







.mobile_main_popularSection {
    width: 90%;
    margin: auto;
}

.mobile_main_popularSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_popularSection>span {
    display: flex;
    flex-direction: row;
    gap: 19px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_main_popularSection>span::-webkit-scrollbar {
    display: none;
}






.mobile_main_reviewSection {
    width: 90%;
    margin: auto;
    position: relative;
}

.mobile_main_reviewSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_reviewSection>p {
    position: absolute;
    top: 5px;
    right: 0;
    ;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    cursor: pointer;
}

.mobile_main_reviewSection>span {
    display: flex;
    flex-direction: row;
    gap: 19px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_main_reviewSection>span::-webkit-scrollbar {
    display: none;
}

.mobile_ReviewCard {
    min-width: 189px;
    max-width: 189px;
    height: 270px;
    border: 1px solid #ededed;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.mobile_ReviewCard>img {
    width: 100%;
    margin-bottom: 12px;
}

.mobile_ReviewCard>h4 {
    width: 90%;
    margin: 0;
    margin: auto;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #111;
    white-space: nowrap;
}

.mobile_ReviewCard>p {
    width: 90%;
    min-height: 28px;
    margin: 0;
    margin: auto;
    margin-bottom: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_ReviewCard>span {
    display: block;
    width: 90%;
    margin: auto;
}








.mobile_main_carmentoSection {
    width: 90%;
    margin: auto;
    position: relative;
}

.mobile_main_carmentoSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_carmentoSection>span {
    display: flex;
    flex-direction: row;
    gap: 19px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_main_carmentoSection>span::-webkit-scrollbar {
    display: none;
}

.mobile_CarmentoCard {
    min-width: 127px;
    max-width: 127px;
    height: 210px;
    cursor: pointer;
    overflow: hidden;
}

.mobile_CarmentoCard>div {
    width: 100%;
    height: 156px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.mobile_CarmentoCard>div>span {
    position: absolute;
    top: 0;
    width: 127px;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.03));
}

.mobile_CarmentoCard>div>img {
    display: flex;
    width: 100%;
}

.mobile_CarmentoCard>h4 {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_CarmentoCard>p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    white-space: pre-wrap;
    cursor: pointer;
}






.mobile_main_partnerSection {
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
    position: relative;
}

.mobile_main_partnerSection>h3 {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_main_partnerSection>img {
    margin: 0;
    width: 100%;
}
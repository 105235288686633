.GNB {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 1600; /* 다른 요소 위에 배치 */
    transition: background-color 0.8s ease, color 0.8s ease; /* 배경색, 테두리, 텍스트 색상 변경 애니메이션 */
}

.GNB.scrolled {
    background-color: white; /* 스크롤된 상태에서의 배경색 */
    border-bottom: 1px solid #ededed; /* 스크롤된 상태에서의 아래쪽 테두리 */
}

.GNB.scrolled .GNBListDiv > span  .listA > p {
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.GNB.scrolled .GNBListDiv > .rightDiv >  p {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.GNB.scrolled .GNBListDiv > .searchDiv {
    background-color: #F0F2F4;
}

.GNBListDiv {
    position: relative;
    min-width: 1280px;
    max-width: 1280px;
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
}

.GNBListDiv > a > img {
    width: 147px;
}

.GNBListDiv > span {
    width: 670px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-left: 90px;
}

.GNBListDiv > span  > .listA {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
}

.GNBListDiv > span  > .listA > p {
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.GNBListDiv > span  > .listA:hover > p {
    font-weight: 600;
    color: #0064FF;
}

.GNB.scrolled .GNBListDiv > span  > .listA:hover > p {
    color: #0064FF;
}

.GNBListDiv > span  > .listA > p.selected {
    color: #0064FF;
}

.GNB.scrolled .GNBListDiv > span  > .listA > p.selected {
    color: #0064FF;
}


.GNBSearchDiv {
    position: relative;
    width: 275px;
    height: calc(80px - 34px);
    border-bottom: 1px solid #111;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.GNBSearchDiv > input {
    width: 250px;
    height: calc(100% - 10px);
    margin-right: 7px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #111;
}

.GNBSearchDiv > input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.GNBSearchDiv > span {
    width: 30px;
    cursor: pointer;
    margin-top: 5px;
}


.GNBSearchListDiv {
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-sizing: border-box;
}

.GNBSearchListDiv > span {
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.GNBSearchListDiv > span > span {
    cursor: pointer;
}


.GNBSearchListCard {
    display: block;
    max-width: 100%;
    min-width: 100%;
}

.GNBSearchListCard > p {
    margin: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #111;
    cursor: pointer;
}

.GNBSearchListCard > p:hover {
    color: #0064FF;
}
.admin_content {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid #bbb;
}


/*로그인 페이지*/

.admin_content>h2 {
    margin: 0;
    font-size: 30px;
    padding: 20px;
}

.admin_content>h2>span {
    font-size: 20px;
    color: #0064FF;
}

.admin_content .header-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #111;
}

.admin_content .header-row>input {
    margin: 20px;
}

.admin_content_loginSection {
    width: 100%;
    height: calc(100vh - 82px);
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin_content_loginDiv {
    background-color: white;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.admin_content_loginDiv > h2 {
    margin: 0;
    padding: 10px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #111;
    border-bottom: 1px solid #bbb;
}

.admin_content_loginDiv > input {
    width: 90%;
    height: 50px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #bbb;
    outline: none;
    font-size: 20px;
}

.admin_content_loginDiv > h3 {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #767676;
}

.admin_content_loginDiv > button {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    background-color: #0064FF;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}




/*옵션 및 색상 추가 및 수정 페이지*/
.admin_content_colorAddSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.admin_content_colorAddSection>div {
    margin: auto;
    background-color: white;
    border: 1px solid #bbb;
}

.admin_content_colorAddSection>div:nth-of-type(1) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(70% - 15px);
    height: 700px;
    overflow: hidden;
}

.admin_content_colorAddSection>div:nth-of-type(1)>h1 {
    font-size: 28px;
    font-weight: 600;
    color: #111;
    margin-left: 20px;
}

.admin_content_colorAddSection>div:nth-of-type(1)>input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 220px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #bbb;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
}

.admin_content_colorAddSection>div:nth-of-type(1)>span:nth-of-type(1) {
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #111;
}

.admin_content_colorAddSection>img {
    width: 38px;
    height: 38px;
    cursor: pointer;
}

.admin_content_colorCardList {
    overflow-y: auto;
}

/* 스크롤바 전체 */
.admin_content_colorCardList::-webkit-scrollbar {
    width: 7px;
    /* 너비 */
}

/* 스크롤바 트랙 */
.admin_content_colorCardList::-webkit-scrollbar-track {
    background: #00ff0000;
    /* 배경색 */
}

/* 스크롤바 핸들 */
.admin_content_colorCardList::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* 색상 */
    border: 2px solid #f0f0f0;
    /* 핸들 주위의 여백 */
}

/* 스크롤바 핸들 호버 시 */
.admin_content_colorCardList::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
    /* 호버 시 색상 */
}


.admin_content_colorCard {
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin_content_colorCard>p:nth-of-type(1) {
    width: 250px;
    margin-left: 50px;
    margin-right: 50px;
}

.admin_content_colorCard>p:nth-of-type(2) {
    width: 100px;
}

.admin_content_colorCard>button {
    position: absolute;
    right: 0px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #FF0000;
    cursor: pointer;
}

.admin_content_colorCard.title {
    height: 50px;
    border: none;
}


.admin_content_colorCard>span {
    margin-left: 20px;
    width: 33px;
    height: 33px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.admin_content_colorCard>p:nth-of-type(2) {
    margin: 0;
    margin-right: 20px;
    width: 90px;
}

.admin_content_colorCard.title>p:nth-of-type(1) {
    margin: 0;
    margin-left: 20px;
    width: 35px;
}

.admin_content_colorCard.title>p:nth-of-type(2) {
    width: 250px;
    margin: 0px 50px;
}

.admin_content_colorCard.title>p:nth-of-type(3) {
    width: 100px;
}







.admin_content_colorAddSection>div:nth-of-type(2) {
    width: calc(30% - 15px);
    height: 700px;
    display: flex;
    flex-direction: column;
}

.admin_content_colorAddSection>div:nth-of-type(2)>h3 {
    width: 90%;
    margin: auto;
    font-size: 24px;
    font-weight: 600;
    color: #111;
    margin-top: 30px;
    margin-bottom: 10px;
}

.admin_content_colorAddSection>div:nth-of-type(2)>input {
    width: calc(90% - 20px);
    height: 40px;
    border: 1px solid #bbb;
    border-radius: 12px;
    outline: none;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0px 10px;
}

.admin_content_colorAddSection>div:nth-of-type(2)>button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50px;
    margin: auto;
    margin-top: 60px;
    border-radius: 4px;
    border: none;
    background-color: #0064FF;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}

.admin_content_HotdealList {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.admin_content_HotdealItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.admin_content_hotdeal-checkbox {
    margin-left: 30px;
    border-radius: 3px;
    width: 15px;
    height: 15px;
}

.admin_content_hotdeal-image {
    width: 160px;
    height: 120px;
    object-fit: cover;
    margin-right: 20px;
    background-color: #ededed;
    border-radius: 0px;
}

.admin_content_hotdeal-info {
    min-width: 150px;
    display: flex;
    margin: 0;
    flex-direction: column;
}

.admin_content_hotdeal-info h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 25px;
}

.admin_content_hotdeal-info p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #767676;
}

.admin_content_hotdeal-buttons {
    display: flex;
    gap: 10px;
}

.admin_content_edit-button,
.admin_content_delete-button {
    height: 35px;
    width: 70px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    border: none;
    border-radius: 45px;
    cursor: pointer;
}

.admin_content_edit-button {
    background-color: #0064FF;
    color: white;
}

.admin_content_delete-button {
    background-color: #FF0000;
    color: white;
}

.admin_content_add-button {
    height: 35px;
    width: 70px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 45px;
    cursor: pointer;
}

.admin_content_event_add-button {
    height: 35px;
    width: 70px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 1580px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 45px;
    cursor: pointer;
}

.admin_content_add-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.admin_content_add-section {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.admin_content_add-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.admin_content_add-form label {
    font-size: 16px;
}

.admin_content_add-buttons {
    display: flex;
    justify-content: space-between;
}

.admin_content_hotdeal_buttonSection {
    display: flex;
    flex-direction: row;
    margin-left: 1200px;
    gap: 10px;
}

.admin_content_hodeal_infosub {
    margin-top: 8px;
    height: 14px;
    flex-direction: row;
    display: flex;
}

.admin_content_hotdeal-infosub>p {
    font-size: 14px;
    font-weight: 500;
    color: #767676;
}

.admin_content_event_infosub {
    margin-top: 8px;
    height: 14px;
    flex-direction: row;
    display: flex;
}

.admin_content_event_infosub>p {

    font-size: 14px;
    font-weight: 500;
    color: #767676;
}

.admin_content_hodeal_line {
    margin-top: 1px;
    width: 5px;
    margin-right: 5px;
    height: 100%;
    border-right: 1px solid #BBB;
}

.admin_content_edit-form {
    display: flex;
    flex-direction: column;
}

.admin_content_edit_text_form {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin_content_edit_text_form>p {
    width: 100px;
}

.admin_content_edit_text_form>input {
    width: 300px;
}

.admin_content_searchListInput {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 220px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #bbb;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
}

/*유저 리스트*/
.admin_content_UserListTitleSpan {
    width: 85%;
    display: flex;
    padding: 0px 20px;
}

.admin_content_UserAllListDiv {}

.admin_content_UserAllListDiv>div {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
}

.admin_content_UserAllListDiv>div>span {
    width: 85%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.admin_content_UserListTitleSpan>p {
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}

.admin_content_UserAllListDiv>div>span>p {
    text-align: center;
}

.admin_content_UserAllListDiv>div>span>span>p {
    text-align: center;
}

.admin_content_UserAllListDiv>div>button {
    width: 55px;
    height: 100%;
    border: none;
    cursor: pointer;
}

.admin_content_UserAllListDiv>div>button:nth-of-type(1) {
    position: absolute;
    right: 55px;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    color: #0064FF;
    font-size: 15px;
}

.admin_content_UserAllListDiv>div>button:nth-of-type(2) {
    position: absolute;
    right: 0px;
    color: #FF0000;
    font-size: 15px;
}









.admin_content_event_add_form {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
}

.admin_content_event_button {
    width: 150px;
    height: 45px;
    background-color: transparent;
    color: #767676;
    border: 1px solid #767676;
    cursor: pointer;
    margin: 0px 0px 10px 20px;
    text-align: center;
}

.admin_content_event_button.active {
    background-color: #007BFF;
    color: white;
    border: none;
}

.admin_content_upload_section {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.admin_content_event_addSection {
    margin-top: 10px;
    height: auto;
    background-color: white;
    z-index: 1001;
}

.admin_content_event_addSection_body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* 스크롤 생성 */
    padding: 20px;
}

.admin_content_event_nameSection,
.admin_content_event_dateSection {
    display: flex;
    flex-direction: column;
}

.admin_content_event_nameSection>input {
    width: 287px;
    height: 31px;
    border-radius: 5px;
}

.admin_content_event_dateSection>h1 {
    font-size: 20px;
    font-weight: 600;
}

.admin_content_event_nameSection>h1 {
    font-size: 20px;
    font-weight: 600;
}

.admin_content_event_bannerSection {
    display: flex;
    flex-direction: column;
}

.admin_content_event_bannerSection>h3 {
    font-size: 20px;
    font-weight: 600;
}


.admin_content_event_bannerSection>img {
    width: 38px;
    height: 38px;
    cursor: pointer;
    margin-top: 10px;
}

.admin_content_event_bannerSection>span {
    color: #ff0000
}

.admin_content_event_banner_img {
    display: flex;
    width: 400px;
    border: 1px solid #cacaca;
    margin-top: 10px;
    box-sizing: border-box;
}

.admin_content_event_mainSection {
    display: flex;
    flex-direction: column;
}

.admin_content_event_mainSection>img {
    width: 38px;
    height: 38px;
    cursor: pointer;
}

.admin_content_event_main_img {
    width: 700px;
    border: 1px solid #cacaca;
    border-radius: 14px;
    margin-top: 10px;
}


.admin_content_save_button {
    padding: 5px 10px;
    background-color: #0064FF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.admin_content_close_button {
    padding: 5px 10px;
    background-color: #d9d9d9;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.date-picker {
    display: flex;
    gap: 10px;
    align-items: center;
}

.date-picker select {
    width: 100px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
}

.admin_content_event_add_buttonSection {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 140px;
    justify-content: center;
    gap: 30px;
}


.admin_content_eventStat_buttonDiv {
    display: flex;
    gap: 15px;
    margin: 0px 0px 10px 20px;
}

.admin_content_eventStat_buttonDiv>button {
    padding: 5px;
    border: 1px solid #767676;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    cursor: pointer;
}

.admin_content_eventStat_buttonDiv>button.selected {
    color: white;
    background-color: #0064FF;
    border: none;
}

.admin_content_listButtonDiv {
    display: flex;
    margin-left: auto;
}

.admin_content_listButtonDiv>button {
    height: 120px;
    width: 55px;
    border: none;
    border-left: 1px solid #dbdbdb;
    cursor: pointer;
    color: #ff0000;
}

.admin_content_listButtonDiv>button:nth-of-type(1) {
    color: #0064FF;
}


.admin_content_reviewListComment {
    min-width: 400px;
    max-width: 400px;
    margin-left: 50px;
}

.admin_content_reviewListComment>p {
    color: #767676;
}

.admin_content_option_preview_img {
    display: flex;
    width: 250px;
    min-height: 100px;
    border: 1px solid #dbdbdb;
    margin-top: 10px;
    margin-left: 20px;
}

.admin_content_FAQ_add {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.admin_content_FAQ_add>h3 {
    font-size: 22px;
    font-weight: 600;
}

.admin_content_FAQ_add>input {
    width: 250px;
    height: 37px;
    border: 1px solid #DBDBDB;
    margin-bottom: 20px;
}

.admin_content_FAQ_preview_img {
    display: flex;
    width: 400px;
    height: 300px;
    border: 1px solid #cacaca;
    margin-top: 10px;

}

.admin_content_FAQ_newcar_bodySection {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_newcar_PriceSection {
    display: flex;
    flex-direction: column;
    margin-right: 150px;
}

.admin_content_FAQ_newcar_PriceSection>h3 {
    font-size: 22px;
    font-weight: 600;
}

.admin_content_FAQ_newcar_PriceSection_input {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_newcar_PriceSection_input>input {
    width: 250px;
    height: 37px;
    border: 1px solid #DBDBDB;
    margin-bottom: 20px;
    margin-right: 10px;
}

.admin_content_FAQ_newcar_PriceSection_input>p {
    font-size: 18px;
    font-weight: 500;
}

.admin_content_FAQ_newcar_DateSection {
    display: flex;
    flex-direction: column;
}

.admin_content_FAQ_newcar_DateSection>h3 {
    font-size: 22px;
    font-weight: 600;
}

.admin_content_FAQ_newcar_DateSection_input {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_cartypeSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 40px;
}

.admin_content_FAQ_cartypeSection>button {
    width: auto;
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    border: 1.5px solid #DBDBDB;
    background-color: #FFFFFF;
    color: #767676;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

}

.admin_content_FAQ_cartypeSection button.selected {
    background-color: #007bff;
    /* 선택된 버튼의 배경색 */
    color: white;
    /* 선택된 버튼의 글자색 */
    border: none;
}

.admin_content_FAQ_cartypeSection .cartype-button:nth-child(5) {
    margin-right: 0;
    /* 오른쪽 여백 제거 */
}

.admin_content_FAQ_MAXMINSection {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
    ;
}

.admin_content_FAQ_MAXMINSection>p {
    width: 40px;
    font-size: 18px;
    font-weight: 500;
    margin-right: 7px;
}

.admin_content_FAQ_MAXMINSection>input {
    width: 106px;
    height: 37px;
    margin-right: 7px;
    border: 1px solid #dbdbdb;
}

.admin_content_FAQ_detailSection {
    display: flex;
    flex-direction: column;
}

.admin_content_FAQ_add_model {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.admin_content_FAQ_add_model>div {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_add_model>div>button {
    border: 1px solid #dbdbdb;
    background-color: transparent;
    color: tomato;
    margin-right: 20px;
    cursor: pointer;
}

.admin_content_FAQ_add_model>div>h4 {
    margin: 0;
    font-size: 18px;
    color: #0064FF;
}

.admin_content_FAQ_add_model>div>h4>span {
    font-size: 22px;
    color: #000000;
}

.admin_content_FAQ_add_model > div > span {
    padding-left: 30px;
}

.admin_content_FAQ_add_model > div > span > p {
    margin: 0;
}

.admin_content_FAQ_detail_Section_input {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.admin_content_FAQ_detail_Section_input > button{
    width: 150px;
    height: 40px;
    background-color: white;
    border: 1px solid #dbdbdb;
    cursor: pointer;
}

.admin_content_FAQ_detail_Section_input > button.selected {
    background-color: #0064FF;
    border: none;
    color: white;
}

.admin_content_FAQ_detail_Section_input>span {
    font-size: 37px;
    color: #000000;
}

.admin_content_FAQ_detail_Section_input>input {
    width: 244px;
    height: 37px;
    border: 1px solid #dbdbdb;
}

.admin_content_FAQ_detailmodel_addbutton {
    width: 92px;
    height: 37px;
    cursor: pointer;
    background-color: #0064FF;
    color: #FFFFFF;
    border: 1.5px solid #0064FF;
}



.admin_content_optionCard.title {
    display: flex;
    flex-direction: row;
}

.admin_content_optionCard.title>p:nth-of-type(1) {
    width: 100px;
    margin-left: 20px;
}

.admin_content_optionCard.title>p:nth-of-type(2) {
    width: 250px;
    margin-left: 50px;
    margin-right: 50px;
}

.admin_content_optionCard.title>p:nth-of-type(3) {
    width: 100px;
}

.admin_content_optionCardList {
    overflow-y: auto;
}

/* 스크롤바 전체 */
.admin_content_optionCardList::-webkit-scrollbar {
    width: 7px;
    /* 너비 */
}

/* 스크롤바 트랙 */
.admin_content_optionCardList::-webkit-scrollbar-track {
    background: #00ff0000;
    /* 배경색 */
}

/* 스크롤바 핸들 */
.admin_content_optionCardList::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* 색상 */
    border: 2px solid #f0f0f0;
    /* 핸들 주위의 여백 */
}

/* 스크롤바 핸들 호버 시 */
.admin_content_optionCardList::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
    /* 호버 시 색상 */
}

.admin_content_optionCard {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    box-sizing: border-box;
}

.admin_content_optionCard>img {
    width: 120px;
}

.admin_content_optionCard>p:nth-of-type(1) {
    margin-left: 50px;
    width: 250px;
}

.admin_content_optionCard>p:nth-of-type(2) {
    margin-left: 50px;
    width: 100px;
}

.admin_content_optionCard>button {
    position: absolute;
    right: 0px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #ff0000;
    cursor: pointer;
}

.admin_content_FAQ_alladd_buttonSection {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.admin_content_FAQ_alladd_addbutton {
    margin: 0;
    width: 100%;
    height: 60px;
    background-color: #0064FF;
    color: #FFFFFF;
    border: 1px solid #0064FF;
    cursor: pointer;
    margin-bottom: 20px;
}

.admin_content_FAQ_alladd_cancelbutton {
    margin: 0;
    width: 80px;
    height: 40px;
    border-radius: 29px;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    cursor: pointer;
}

.admin_content_FAQ_ColorAddDiv {
    margin-top: 50px;
}

.admin_content_FAQ_ColorAddDiv>span {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(1) {
    width: 500px;
    height: 600px;
    border: 1px solid #dbdbdb;
    margin-right: 10px;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(1) > div:nth-of-type(2) {
    overflow-y: auto;
    height: 498px;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(1)>input {
    float: right;
    width: 220px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #bbb;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px 10px 0px 0px;
    outline: none;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(1)>span {
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #dbdbdb;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2) {
    width: 500px;
    height: 600px;
    border: 1px solid #DBDBDB;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2) > span {
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #dbdbdb;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2)>div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2) > div:nth-of-type(3) {
    overflow-y: auto;
    max-height: 498px;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2)>div>input {
    float: left;
    width: 150px;
    height: 35px;
    border-radius: 20px;
    border: 1px solid #bbb;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px 0px 0px 10px;
    outline: none;
}

.admin_content_FAQ_ColorAddDiv>span>div:nth-of-type(2)>div>button {
    margin-left: auto;
    margin-right: 10px;
    margin-top: 10px;
    width: 100px;
    border: none;
    background-color: #0064FF;
    color: white;
    cursor: pointer;
}

.admin_content_carListDeleteButton {
    display: inline-block;
    height: 120px;
    margin-left: auto;
    width: 55px;
    border: none;
    border-left: 1px solid #dbdbdb;
    cursor: pointer;
    color: tomato;
}

.admin_content_carListAddButton {
    display: inline-block;
    height: 120px;
    margin-left: auto;
    width: 55px;
    border: none;
    border-left: 1px solid #dbdbdb;
    cursor: pointer;
    color: blue;
}

.admin_content_hotDealList_EditDiv {
    width: 100%;
    height: 200px;
    background-color: #ededed;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin_content_hotDealList_EditDiv > span {
    margin-left: 20px;
    margin-right: 30px;
}

.admin_content_hotDealList_EditDiv > span > p {
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
}

.admin_content_hotDealList_EditDiv > span> input {
    padding: 10px;
    outline: none;
    margin-bottom: 20px;
}

.admin_content_hotDealList_EditDiv > span> span {
    display: flex;
    gap: 10px;
}

.admin_content_hotDealList_EditDiv > span> span > button {
    padding: 10px;
    outline: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.admin_content_hotDealList_EditDiv > span> span > button.selected {
    border: none;
    background-color: #0064FF;
    color: white;
}

.admin_content_hotDealList_EditDiv > button {
    width: 100px;
    height: 160px;
    border: none;
    color: white;
    background-color: #0064FF;
    cursor: pointer;
}

.admin_content_FAQ_add_modelOptionList {
    max-width: 1000px;
    height: 50px;
    background-color: #0064FF;
    color: white;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}








.admin_content_quickDealUserListTitleSpan {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    box-sizing: border-box;
}

.admin_content_quickDealUserListTitleSpan > p {
    font-size: 14px;
    font-weight: 400;
    color: #111;
    margin: 0;
    text-align: center;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(1) {
    width: 11%;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(2) {
    width: 8%;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(3) {
    width: 12%;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(4) {
    width: 15%;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(5) {
    width: 40%;
}

.admin_content_quickDealUserListTitleSpan > p:nth-of-type(6) {
    width: 11%;
}


.admin_content_quickDealUserListDiv {
    position: relative;
    width: 100%;
}

.admin_content_quickDealUserListDiv > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dbdbdb;
}

.admin_content_quickDealUserListDiv > div > span {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
    justify-content: space-between;
}

.admin_content_quickDealUserListDiv > div > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_quickDealUserListDiv > div > span > p > span {
    font-size: 12px;
    font-weight: 400;
    color: #dbdbdb;
    margin: 0;
}

.admin_content_quickDealUserListDiv > div > span > span > p > span {
    font-size: 12px;
    font-weight: 400;
    color: #dbdbdb;
    margin: 0;
}

.admin_content_quickDealUserListDiv > div > span > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_quickDealUserListDiv > div > span > p:nth-of-type(1) {
    width: 11%;
}

.admin_content_quickDealUserListDiv > div > span > p:nth-of-type(2) {
    width: 8%;
}

.admin_content_quickDealUserListDiv > div > span > p:nth-of-type(3) {
    width: 12%;
}

.admin_content_quickDealUserListDiv > div > span > p:nth-of-type(4) {
    width: 15%;
}

.admin_content_quickDealUserListDiv > div > span > p:nth-of-type(5) {
    width: 11%;
}

.admin_content_quickDealUserListDiv > div > span > span {
    width: 40%;
}

.admin_content_quickDealUserListDiv > div > button:nth-of-type(1) {
    position: absolute;
    right: 55px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #0064FF;
    font-weight: 400;
    cursor: pointer;
}

.admin_content_quickDealUserListDiv > div > button:nth-of-type(2) {
    position: absolute;
    right: 0px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #FF0000;
    font-weight: 400;
    cursor: pointer;
}











.admin_content_quickFAQUserListTitleSpan {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    box-sizing: border-box;
}

.admin_content_quickFAQUserListTitleSpan > p {
    font-size: 14px;
    font-weight: 400;
    color: #111;
    margin: 0;
    text-align: center;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(1) {
    width: 5%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(2) {
    width: 8%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(3) {
    width: 12%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(4) {
    width: 12%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(5) {
    width: 25%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(6) {
    width: 25%;
}

.admin_content_quickFAQUserListTitleSpan > p:nth-of-type(7) {
    width: 7%;
}


.admin_content_quickFAQUserListDiv {
    position: relative;
    width: 100%;
}

.admin_content_quickFAQUserListDiv > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dbdbdb;
}

.admin_content_quickFAQUserListDiv > div > span {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
    justify-content: space-between;
}

.admin_content_quickFAQUserListDiv > div > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_quickFAQUserListDiv > div > span > p > span {
    font-size: 12px;
    font-weight: 400;
    color: #dbdbdb;
    margin: 0;
}

.admin_content_quickFAQUserListDiv > div > span > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_quickFAQUserListDiv > div > span > span > p > span{
    font-size: 12px;
    font-weight: 400;
    color: #dbdbdb;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_quickFAQUserListDiv > div > span > p:nth-of-type(1) {
    width: 5%;
}

.admin_content_quickFAQUserListDiv > div > span > p:nth-of-type(2) {
    width: 8%;
}

.admin_content_quickFAQUserListDiv > div > span > p:nth-of-type(3) {
    width: 12%;
}

.admin_content_quickFAQUserListDiv > div > span > p:nth-of-type(4) {
    width: 12%;
}

.admin_content_quickFAQUserListDiv > div > span > p:nth-of-type(5) {
    width: 7%;
}

.admin_content_quickFAQUserListDiv > div > span > span:nth-of-type(1) {
    width: 25%;
}

.admin_content_quickFAQUserListDiv > div > span > span:nth-of-type(2) {
    width: 25%;
}

.admin_content_quickFAQUserListDiv > div > button:nth-of-type(1) {
    position: absolute;
    right: 55px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #0064FF;
    font-weight: 400;
    cursor: pointer;
}

.admin_content_quickFAQUserListDiv > div > button:nth-of-type(2) {
    position: absolute;
    right: 0px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #FF0000;
    font-weight: 400;
    cursor: pointer;
}










.admin_content_carmentoUserListTitleSpan {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    box-sizing: border-box;
}

.admin_content_carmentoUserListTitleSpan > p {
    font-size: 14px;
    font-weight: 400;
    color: #111;
    margin: 0;
    text-align: center;
}

.admin_content_carmentoUserListTitleSpan > p:nth-of-type(1) {
    width: 15%;
}

.admin_content_carmentoUserListTitleSpan > p:nth-of-type(2) {
    width: 10%;
}

.admin_content_carmentoUserListTitleSpan > p:nth-of-type(3) {
    width: 15%;
}

.admin_content_carmentoUserListTitleSpan > p:nth-of-type(4) {
    width: 40%;
}



.admin_content_carmentoUserListDiv {
    position: relative;
    width: 100%;
}

.admin_content_carmentoUserListDiv > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dbdbdb;
}

.admin_content_carmentoUserListDiv > div > span {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
    justify-content: space-between;
}

.admin_content_carmentoUserListDiv > div > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.admin_content_carmentoUserListDiv > div > span > p:nth-of-type(1) {
    width: 15%;
}

.admin_content_carmentoUserListDiv > div > span > p:nth-of-type(2) {
    width: 10%;
}

.admin_content_carmentoUserListDiv > div > span > p:nth-of-type(3) {
    width: 15%;
}

.admin_content_carmentoUserListDiv > div > span > p:nth-of-type(4) {
    width: 40%;
}



.admin_content_carmentoUserListDiv > div > button:nth-of-type(1) {
    position: absolute;
    right: 55px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #0064FF;
    font-weight: 400;
    cursor: pointer;
}

.admin_content_carmentoUserListDiv > div > button:nth-of-type(2) {
    position: absolute;
    right: 0px;
    width: 55px;
    height: 100%;
    border: none;
    border-left: 1px solid #dbdbdb;
    color: #FF0000;
    font-weight: 400;
    cursor: pointer;
}
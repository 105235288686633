.mainPage_BannerSection {
    width: 1280px;
    height: 490px;
    margin: auto;
    margin-top: 200px;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.mainPage_BannerSection>div:nth-of-type(1) {
    position: relative;
    min-width: 340px;
    max-width: 260px;
    height: 100%;
    background-color: white;
    border: 1px solid #0064FF;
    border-radius: 12px;
    z-index: 900;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}



.mainPage_BannerSection>div:nth-of-type(1)>img {
    width: 115px;
    margin-top: 12px;
    margin-bottom: 15px;
}

.mainPage_BannerSection>div:nth-of-type(1)>h1 {
    font-size: 25px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin-bottom: 5px;
}

.mainPage_BannerSection>div:nth-of-type(1)>h3 {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
    margin-bottom: 23px;
}

.mainPage_BannerSection>div:nth-of-type(1)>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 17px;
}

.mainPage_BannerSection>div:nth-of-type(1)>div>h4 {
    font-size: 16px;
    font-weight: 500;
    color: #111;
    margin: 0;
    margin-right: 13px;
    width: 43px;
    text-align: right;
}

.mainPage_BannerSection>div:nth-of-type(1)>div>input {
    width: 240px;
    height: 45px;
    border: 1px solid #A9A9A9;
    outline: none;
    border-radius: 6px;
    padding: 0px 10px;
    box-sizing: border-box;
}

.mainPage_BannerSection>div:nth-of-type(1)>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    margin-top: 15px;
}

.mainPage_BannerSection>div:nth-of-type(1)>span>img {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-right: 7px;
    cursor: pointer;
}

.mainPage_BannerSection>div:nth-of-type(1)>span>p {
    font-size: 12px;
    font-weight: 500;
    color: #7D7D7D;
    margin: 0;
    white-space: nowrap;
}

.mainPage_BannerSection>div:nth-of-type(1)>span>p>span:nth-of-type(1) {
    font-size: 12px;
    font-weight: 500;
    color: #0064FF;
    margin: 0;
}

.mainPage_BannerSection>div:nth-of-type(1)>span>p>span:nth-of-type(2) {
    margin: 0;
    cursor: pointer;
}


.mainPage_BannerSection>div:nth-of-type(1)>button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-height: 55px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    background-color: #0064FF;
    border: none;
    margin: 0;
    cursor: pointer;
}



.mainPage_BannerSection>div:nth-of-type(2) {
    position: relative;
    width: 900px;
    height: 100%;
    background-color: #c4c4c4;
    margin-left: auto;
    border-radius: 16px;
    overflow: hidden;
}

.mainPage_BannerSection>div:nth-of-type(2)>button {
    background-color: #767676;
    opacity: 80%;
    color: white;
    border: none;
    width: 30px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
}

.mainPage_BannerSection>div:nth-of-type(2)>button:nth-of-type(1) {
    position: absolute;
    left: 20px;
    bottom: 20px;
    padding: 0px 0px 0px 0px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.mainPage_BannerSection>div:nth-of-type(2)>button:nth-of-type(2) {
    position: absolute;
    left: 50px;
    bottom: 20px;
    padding: 0px 0px 0px 10px;
}

.mainPage_BannerSection>div:nth-of-type(2)>button:nth-of-type(3) {
    position: absolute;
    left: 80px;
    bottom: 20px;
    font-weight: 600;
}

.mainPage_BannerSection>div:nth-of-type(2)>div {
    min-width: 100%;
    display: flex;
    flex-direction: row;
}

.mainPage_BannerSection>div:nth-of-type(2)>div>img {
    min-width: 100%;
    cursor: pointer;
}

.mainPage_BannerSection>div:nth-of-type(2)>span {
    width: 60px;
    height: 40px;
    background-color: #767676;
    opacity: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 110px;
    bottom: 20px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.mainPage_BannerSection>div:nth-of-type(2)>span>p {
    font-size: 16px;
    color: white;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 5px;
    cursor: default;
}

.mainPage_BannerSection>div:nth-of-type(2)>span>p>span {
    font-size: 24px;
    color: white;
    font-weight: 600;
}




.eventBanner_indicator-container {
    position: absolute;
    left: 190px;
    bottom: 35px;
}

.eventBanner_indicator-container>.indicator-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d3d3d3;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.eventBanner_indicator-container>.indicator-dot.active {
    background-color: #0064FF;
}





























.eventLineBannerSection {
    position: relative;
    max-width: 1280px;
    min-width: 1280px;
    min-height: 700px;
    margin: auto;
    margin-top: 120px;
}

.eventLineBannerSection>video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 700px;
}

.eventLineBannerSection>img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 700px;
}

.eventLineBannerSection>button:nth-of-type(1) {
    position: absolute;
    top: 400px;
    left: 20px;
    width: 70px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.eventLineBannerSection>button:nth-of-type(2) {
    position: absolute;
    top: 400px;
    right: 20px;
    width: 70px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;   
}

.eventLineBannerSection>span {
    position: absolute;
    top: 330px;
    left: 120px;
    min-width: 1040px;
    max-width: 1040px;
    overflow: hidden;
}

.eventLineBannerSection>span>span {
    max-width: 1040px;
    min-width: 1040px;
    height: 180px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.eventLineBannerSection>span>span>img {
    min-width: 340px;
    max-width: 340px;
    min-height: 180px;
    max-width: 180px;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #ededed;
    box-sizing: border-box;
}

/* 슬라이더 컨트롤 스타일 */
.slider-controls {

    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .slider-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: gray;
    border-radius: 20px;
  }
  
  .prev-button,
  .next-button {
    background-color: gray;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
  }
  
  .slider-info p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }
  
  .toggle-button {
    padding-bottom: 5px;
    border-radius: 50%;
    background-color: gray;
    border: none;
    color: white;
    font-size: 20px;
  }
  
  .slider-button button {
    cursor: pointer;
  }
  
  .slider-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  /* 스타일을 수정하여 위치 및 외관을 설정 */
  .slider-controls {    
    position: absolute;
    bottom: 20px;
    left: 52%;
    transform: translateX(-50%);
    z-index: 10;
  }
  
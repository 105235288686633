body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-spacing: -0.1rem;
  font-family: 'Pretendard-Regular';
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  word-spacing: -4px;
}

/* 스크롤바 전체 */
body::-webkit-scrollbar {
  width: 10px; /* 너비 */
}

/* 스크롤바 트랙 */
body::-webkit-scrollbar-track {
  background: #00ff0000; /* 배경색 */
}

/* 스크롤바 핸들 */
body::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* 색상 */
  border: 2px solid #f0f0f0; /* 핸들 주위의 여백 */
}

/* 스크롤바 핸들 호버 시 */
body::-webkit-scrollbar-thumb:hover {
  background-color: #b4b4b4; /* 호버 시 색상 */
}
body {
    
}

.koreaLogoDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 100px;
}

.koreaLogoDiv > div {
    width: 133px;
    height: 133px;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    transition: border 0.0s;
}

.koreaLogoDiv > div.selectedLogo {
    border: 1px solid #0064FF;
}

.koreaLogoDiv > div > p {
    margin: 0;
    margin-top: 5px;
}

.koreaLogoDiv > div.selectedLogo > p {
    color: #0064FF;
    font-weight: 600;
}





.FAQ_koreaLogoDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}

.FAQ_koreaLogoDiv > div {
    width: 133px;
    height: 133px;
    border: 1px solid #dbdbdb;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    transition: border 0.0s;
}

.FAQ_koreaLogoDiv > div.selectedLogo {
    border: 1px solid #0064FF;
}

.FAQ_koreaLogoDiv > div > p {
    margin-bottom: 0;
    margin-top: 12px;
}

.FAQ_koreaLogoDiv > div.selectedLogo > p {
    color: #0064FF;
    font-weight: 600;
}
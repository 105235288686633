.mobile_hotDeal_eventBannerSection {
    width: 100%;
}

.mobile_hotDeal_eventBannerSection > img {
    width: 100%;
    cursor: pointer;
}

.mobile_hotDeal_hotDealListSection {
    width: 90%;
    min-height: 400px;
    margin: auto;
    margin-bottom: 60px;
}

.mobile_hotDeal_hotDealListSection > h3 {
    margin: 0;
    margin-top: 40px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_hotDeal_hotDealListSection > p {
    margin: 0;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #767676;
}

.mobile_hotDeal_hotDealListSection > span {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
}


.mobile_hotDealPageCard {
    width: calc(50% - 8px);
    cursor: pointer;
    position: relative;
}

.mobile_hotDealPageCard > div:nth-of-type(1) > img {
    width: 70px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.mobile_hotDealPageCard > div:nth-of-type(2) > img {
    width: 60px;
    position: absolute;
    top: 0;
    left: 0;
}

.mobile_hotDealPageCard>img {
    width: 100%;
    background-color: #f5f5f5;
}

.mobile_hotDealPageCard>h4 {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_hotDealPageCard>p {
    max-height: 28px;
    margin: 0;
    margin-bottom: 8px;
    min-height: 28px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_hotDealPageCard>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.mobile_hotDealPageCard>span>p:nth-of-type(1) {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_hotDealPageCard>span>p:nth-of-type(2) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_hotDealPageCard>span>p:nth-of-type(2)>span:nth-of-type(1) {
    font-weight: 400;
    color: tomato;
}

.mobile_hotDealPageCard>span>p:nth-of-type(2)>span:nth-of-type(2) {
    font-weight: 400;
}

.mobile_hotDealPageCard>span:nth-of-type(3) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.mobile_hotDealPageCard>span:nth-of-type(3)>p {
    margin: 0;
    margin-top: 8px;
    padding: 6px 4px;
    background-color: #F0F2F4;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_hotDealPageCard>button {
    display: block;
    width: 100%;
    height: 30px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #0064FF;
    border: none;
    color: white;
    font-size: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.mobile_hotDealPageCard>button:hover {
    background-color: blue;
}
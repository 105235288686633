.mobile_quickDealPageCard {
    width: calc(50% - 8px);
    cursor: pointer;
    position: relative;
}

.mobile_quickDealPageCard > div > img {
    width: 90px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.mobile_quickDealPageCard>img {
    width: 100%;
    background-color: #f5f5f5;
}

.mobile_quickDealPageCard>h4 {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_quickDealPageCard>p {
    max-height: 28px;
    margin: 0;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_quickDealPageCard>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
}

.mobile_quickDealPageCard>span>p:nth-of-type(1) {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    margin-right: 10px;
}

.mobile_quickDealPageCard>span>p:nth-of-type(2) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: #111;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_quickDealPageCard>span>p:nth-of-type(2)>span:nth-of-type(1) {
    font-weight: 400;
    color: tomato;
}

.mobile_quickDealPageCard>span>p:nth-of-type(2)>span:nth-of-type(2) {
    font-weight: 400;
}

.mobile_quickDealPageCard>span>p:nth-of-type(3) {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    color: #0064FF;
}

.mobile_quickDealPageCard>span:nth-of-type(4) {
    margin-top: 16px;
}

.mobile_quickDealPageCard>span:nth-of-type(6) {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.mobile_quickDealPageCard>span:nth-of-type(6)>p {
    margin: 0;
    padding: 6px 4px;
    background-color: #F0F2F4;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_quickDealPageCard>button {
    display: block;
    width: 100%;
    height: 30px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #0064FF;
    border: none;
    color: white;
    font-size: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.mobile_quickDealPageCard>button:hover {
    background-color: blue;
}
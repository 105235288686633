.mobile_popupDimmed {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}


.mobile_carmentoPopupDiv {
    width: 90%;
    height: 400px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
}



.mobile_carmentoPopupDiv>div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    overflow: hidden;
}

.mobile_carmentoPopupDiv>div:nth-of-type(1)>span:nth-of-type(1) {
    display: flex;
    justify-content: center;
    width: 71px;
    height: 71px;
    box-sizing: border-box;
    border: 1px solid #ededed;
    border-radius: 100px;
    background-color: #DBE0E4;
    overflow: hidden;
}

.mobile_carmentoPopupDiv>div:nth-of-type(1)>span:nth-of-type(1)>img {
    height: 71px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(1)>span:nth-of-type(2) {
    margin-left: 20px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(1)>span:nth-of-type(2)>h2 {
    font-size: 16px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin-bottom: 10px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(1)>span:nth-of-type(2)>p {
    text-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
    margin: 0;
}


.mobile_carmentoPopupDiv>div:nth-of-type(2) {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>h2 {
    font-size: 20px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin-bottom: 24px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>h4 {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    margin: 0;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>input {
    margin-left: auto;
    width: 220px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #bbb;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>img {
    cursor: pointer;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>p {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    margin: 0;
    margin-left: 11px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>p>span {
    color: #767676;
    cursor: pointer;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>span {
    height: 47px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>span:nth-of-type(1) {
    width: 100px;
    border: 1px solid #bbb;
    font-size: 12px;
    font-weight: 500;
    color: #767676;
}

.mobile_carmentoPopupDiv>div:nth-of-type(2)>span>span:nth-of-type(2) {
    width: 200px;
    background-color: #0064FF;
    font-size: 12px;
    font-weight: 500;
    color: white;
    margin-left: auto;
}




.mobile_termsPopupDiv {
    width: 90%;
    height: 400px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    z-index: 2000;
}

.mobile_termsPopupDiv>span {
    display: block;
    max-height: 350px;
    overflow-y: scroll;
}

.mobile_termsPopupDiv>button {
    width: 100%;
    height: 50px;
    border: none;
    cursor: pointer;
}




.mobile_optionPopupDiv {
    width: 90%;
    height: 379px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    z-index: 2000;
}

.mobile_optionPopupDiv>span:nth-of-type(3) {
    margin: 0;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.mobile_optionPopupDiv>h3 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #111;
}

.mobile_optionPopupDiv>p {
    margin: 0;
    width: 90%;
    margin: auto;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_optionPopupDiv>p>span {
    font-weight: 600;
}

.mobile_optionPopupDiv>p:nth-of-type(1) {
    color: #111;
}

.mobile_optionPopupDiv>p:nth-of-type(4) {
    color: #111;
}

.mobile_optionPopupDiv>div {
    width: 90%;
    height: 1px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
}

.mobile_optionPopupDiv>span:nth-of-type(1) {
    display: block;
    width: 90%;
    margin: auto;
    max-height: 100px;
    min-height: 100px;
    overflow-y: auto;
}

/* 스크롤바 전체 */
.mobile_optionPopupDiv>span:nth-of-type(1)::-webkit-scrollbar {
    width: 10px;
    /* 너비 */
}

/* 스크롤바 트랙 */
.mobile_optionPopupDiv>span:nth-of-type(1)::-webkit-scrollbar-track {
    background: #00ff0000;
    /* 배경색 */
}

/* 스크롤바 핸들 */
.mobile_optionPopupDiv>span:nth-of-type(1)::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* 색상 */
}

/* 스크롤바 핸들 호버 시 */
.mobile_optionPopupDiv>span:nth-of-type(1)::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
    /* 호버 시 색상 */
}

.mobile_optionPopupDiv>span:nth-of-type(1)>p {
    margin: 0;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_optionPopupDiv>span:nth-of-type(2) {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile_optionPopupDiv>span:nth-of-type(2)>p:nth-of-type(1) {
    margin: 0;
}

.mobile_optionPopupDiv>span:nth-of-type(2)>p:nth-of-type(2) {
    margin: 0;
    margin-left: auto;
    color: #0064FF;
}


.mobile_searchPopup {
    padding-top: env(safe-area-inset-top, 20px); /* 기본값을 20px로 설정 */
    padding-bottom: env(safe-area-inset-bottom, 20px);
    padding-left: env(safe-area-inset-left, 20px);
    padding-right: env(safe-area-inset-right, 20px);
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.mobile_searchPopup > span {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
}

.mobile_searchPopup > span > span {
    cursor: pointer;
}

.mobile_searchPopup > span > span:nth-of-type(1) {
    margin-right: 10px;
    margin-left: 10px;
}

.mobile_searchPopup > span > span:nth-of-type(2) {
    margin-left: auto;
    margin-right: 20px;
}

.mobile_searchPopup > span > input {
    width: 100%;
    height: 46px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: none;
}

.mobile_searchPopup > span > input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_searchPopup > div {
    display: block;
    height: calc(100% - 77px);
    overflow-y: auto;
}

/* 스크롤바 전체 */
.mobile_searchPopup > div::-webkit-scrollbar {
    width: 10px;
    /* 너비 */
}

/* 스크롤바 트랙 */
.mobile_searchPopup > div::-webkit-scrollbar-track {
    background: #00ff0000;
    /* 배경색 */
}

/* 스크롤바 핸들 */
.mobile_searchPopup > div::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* 색상 */
}

/* 스크롤바 핸들 호버 시 */
.mobile_searchPopup > div::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
    /* 호버 시 색상 */
}



.mobile_AdminPopupDiv {
    width: 90%;
    height: 500px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    z-index: 2000;
}

.mobile_AdminPopupDiv>h5 {
    margin: 0;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.mobile_AdminPopupDiv>h3 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #111;
}

.mobile_AdminPopupDiv>div {
    width: 90%;
    height: 1px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #dbdbdb;
}

.mobile_AdminPopupDiv > span {
    display: block;
    max-height: 400px;
    overflow-y: auto;
}

/* 스크롤바 전체 */
.mobile_AdminPopupDiv > span::-webkit-scrollbar {
    width: 10px;
    /* 너비 */
}

/* 스크롤바 트랙 */
.mobile_AdminPopupDiv > span::-webkit-scrollbar-track {
    background: #00ff0000;
    /* 배경색 */
}

/* 스크롤바 핸들 */
.mobile_AdminPopupDiv > span::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    /* 색상 */
}

/* 스크롤바 핸들 호버 시 */
.mobile_AdminPopupDiv > span::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
    /* 호버 시 색상 */
}

.mobile_AdminPopupDiv > span > span {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.mobile_AdminPopupDiv > span > span > p:nth-of-type(1) {
    width: 70px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #767676;
}

.mobile_AdminPopupDiv > span > span > h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #111;
    width: 200px;
}

.mobile_AdminPopupDiv > span > span > span {
    display: flex;
    flex-direction: column;
}

.mobile_AdminPopupDiv > span > span > span > h4 > span {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_AdminPopupDiv > span > span > span > h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #111;
    width: 200px;
}
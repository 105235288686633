.mobile_review_contentSection {
    width: 90%;
    margin: auto;
    min-height: 600px;
    margin-bottom: 60px;
}

.mobile_review_contentSection>h3 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_review_contentSection>p {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_review_contentSection>button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #0064FF;
    cursor: pointer;
    margin-bottom: 20px;
}

.mobile_reviewPageCard {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #ededed;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    box-sizing: border-box;
}

.mobile_reviewPageCard>img {
    width: 100%;
    background-color: #ededed;
}

.mobile_reviewPageCard>h4 {
    margin: 0;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_reviewPageCard>p {
    margin: 0;
    width: 90%;
    margin: auto;
    margin-bottom: 16px;
    min-height: 84px;
    max-height: 84px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}

.mobile_reviewPageCard>div:nth-of-type(1) {
    width: 90%;
    height: 1px;
    margin: auto;
    border-bottom: 1px solid #ededed;
    margin-bottom: 16px;
}


.mobile_reviewPageCard>span {
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-bottom: 12px;
}

.mobile_reviewPageCard>span>h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #111;
}

.mobile_reviewPageCard>span>p {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewPageCard>span>h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewPageCard>span>span {
    margin-left: auto;
}


.mobile_reviewAdd_section {
    width: 90%;
    margin: auto;
}

.mobile_reviewAdd_section>h3 {
    text-align: center;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_reviewAdd_section>p {
    text-align: center;
    margin: 0;
    margin-bottom: 31px;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewAdd_section>span>h3 {
    margin-right: 10px;
    margin-bottom: 10px;
}

.mobile_reviewAdd_section>span>input {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
}

.mobile_reviewAdd_section>span>span {
    cursor: pointer;
}

.mobile_reviewAdd_section>span>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile_reviewAdd_section>span>div>img {
    width: 33px;
    height: 33px;
    margin-right: 30px;
}

.mobile_reviewAdd_section>span>textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    min-height: 300px;
    resize: none;
}

.mobile_reviewAdd_section>button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #0064FF;
    margin-top: 16px;
    margin-bottom: 60px;
    cursor: pointer;
}



.mobile_reviewMore_contentSection {
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
}

.mobile_reviewMore_contentSection>h3 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_reviewMore_contentSection>p:nth-of-type(1) {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewMore_contentSection>img {
    width: 100%;
    background-color: #dbdbdb;
}

.mobile_reviewMore_contentSection>h4 {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #111;
}

.mobile_reviewMore_contentSection>p:nth-of-type(2) {
    margin: 0;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewMore_contentSection>span:nth-of-type(1) {
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 16px;
}

.mobile_reviewMore_contentSection>span:nth-of-type(2) {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.mobile_reviewMore_contentSection>span:nth-of-type(3) {
    display: flex;
    align-items: center;
}

.mobile_reviewMore_contentSection>span>h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #111;
}

.mobile_reviewMore_contentSection>span>p {
    margin: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewMore_contentSection>span>h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.mobile_reviewMore_contentSection>span>span {
    margin-left: auto;
}


.mobile_reviewMore_listSection {
    width: 90%;
    margin: auto;
    margin-bottom: 60px;
}

.mobile_reviewMore_listSection>h3 {
    margin: 0;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #111;
}

.mobile_reviewMore_listSection>span {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 16px;
    /* IE와 Edge에서 스크롤바 숨기기 */
    scrollbar-width: none;
    /* Firefox에서 스크롤바 숨기기 */
}

/* 웹킷 브라우저에서 스크롤바 숨기기 */
.mobile_reviewMore_listSection>span::-webkit-scrollbar {
    display: none;
}

.mobile_reviewMore_listSection > button {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border: 1px solid #dbdbdb;
    background-color: transparent;
    color: #767676;
    cursor: pointer;
}
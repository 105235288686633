.event_container{
    max-width: 1280px;
    min-width: 1280px;
    min-height: calc(100vh - 370px);
    margin: auto;
}

.eventTitleSection {
    width: 100%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 180px;
    margin-bottom: 40px;
    display: block;
}

.eventTitleSection>h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 600;
    color: #111;
    margin-bottom: 12px;
}

.eventTitleSection>p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: #767676;
    margin-bottom: 12px;
}

.eventButton {
    padding: 10.2px 42px;
    font-size: 18px;
    color: #767676;
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 2px solid #dcdcdc;
}

.eventButtonSection {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    gap: 30px;
    margin-bottom: 40px;
    background-color: #ffffff;
}

.eventButton.selected {
    background-color: #0064FF;
    color: #ffffff;
    border: 2px solid #0064FF;
}

.eventpageSection {
    width: 100%;
    display: flex;
    margin:auto;
    margin-bottom: 120px;
}

.eventList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
    width: 100%;
}

.eventCardlist {
    width: 406px;
    box-sizing: border-box;
    cursor: pointer;
}

.cardImgSection {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 12px;
}

.eventCardImg {
    width: 100%;
    max-height: 200px;
    min-height: 200px; 
    height: auto; 
    object-fit: cover; 
    display: block;
    border: 1px solid #ededed;
    border-radius: 12px;
}


.overlayText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 어두운 오버레이 적용 */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 12px;
    z-index: 2;
}





.eventCardTitle>h2 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    max-height: 45px;
    min-height: 45px;
}

.eventCardTitle>p {
    font-size: 16px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    margin-bottom: 20px;
}


.cardImgSection > div {
    position: absolute;
    top: -10px;
    left: -10px;
    padding: 10px;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    background-color: #0064FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.cardImgSection > div > p {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
}

.cardImgSection > div > span {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
}
.admin_Sidebar_sidebar {
    width: 300px;
    height: 100%;
    background-color: white;
    color: white;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #bbb;
}

.admin_Sidebar_sidebar > .logo {
    width: 200px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.admin_Sidebar_sidebar > .logo img {
    width: 100%;
    margin-bottom: 20px;
}

.admin_Sidebar_sidebar > .menu {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
}

.admin_Sidebar_sidebar > .menu > span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #dbdbdb;
    cursor: pointer;
}

.admin_Sidebar_sidebar > .menu > span.selected > p {
    color: #0064FF;
}

.admin_Sidebar_sidebar > .menu > span > span {
    margin-left: auto;
    margin-right: 20px;
    margin-top: 5px;
}

.admin_Sidebar_sidebar > .menu > span > p {
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-left: 20px;
    cursor: pointer;
    background-color: white;
}


.admin_Sidebar_sidebar > .add-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    color: #0064FF;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}


.admin_Sidebar_categoryDiv {
    background-color: #ededed;
    border-bottom: 1px solid #dbdbdb;
    transition: all 0.3s ease;
}

.admin_Sidebar_categoryDiv > p {
    margin: 20px;
    color: gray;
    font-weight: 400;
    cursor: pointer;
}

.admin_Sidebar_categoryDiv > p.selected {
    color: #0064FF;
}
.titleSection {
    min-width: 1280px;
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.titleSection > h1 {
    font-size: 40px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin-top: 180px;
    margin-bottom: 12px;
    gap: 30px;
}

.titleSection > p {
    font-size: 16px;
    font-weight: 500;
    color: #767676;
    margin: 0;
    margin-bottom: 40px;
}

.titleSection > span {
    width: 210px;
    height: 51px;
    background-color: #0064FF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    margin: auto;
    margin-bottom: 40px;
}



.reviewCardSection {
    min-width: 1280px;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 200px;
}

.reviewCardSection > span {
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.reviewCardSection > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.reviewCardSection > div > p {
    font-size: 18px;
    font-weight: 500;
    color: #767676;
    margin: 0;
    cursor: pointer;
}
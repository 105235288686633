/* 전체 페이지의 가로 스크롤을 막기 */
body, html {
  /* 전체 페이지에서 가로 스크롤을 막음 */
  margin: 0;
  /* 기본 여백 제거 */

}







.hotDealSection {
  min-width: 1280px; 
  max-width: 1280px;
  position: relative;
  margin: auto;
  text-align: left;
  overflow: hidden;
}


.hotDealSection>h1 {
  font-size: 40px;
  font-weight: 400;
  margin-top: 120px;
  margin-bottom: 40px;
  color: #111;
}

.hotDealSection>h1>span {
  font-weight: 600;
}


.hotDealSection>p {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #767676;
}

.hotDealListDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  transition: all 0.3s ease;
  gap: 40px;
}

.hotDealCard {
  position: relative;
  min-width: 290px;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #f5f5f5;
}

.hotDealCard:hover {
  border: 1px solid #0064FF;
}

.hotDealCard > div > img {
  width: 90px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.hotDealCardImg {
  max-width: 100%;
  height: 227px;
  transition: all 0.3s ease;
  background-color: #f5f5f5;
  margin-bottom: 16px;
}

.hotDealCardTitleDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hotDealCardTitleDiv>h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #111;
}

.hotDealCardModel {
  width: 90%;
  min-height: 15px;
  max-height: 15px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #111;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.quickDealCardModel {
  width: 90%;
  min-height: 15px;
  max-height: 15px;
  margin: 0;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #111;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.hotDealCardMonthPriceDiv {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: auto;
  margin-bottom: 8px;
}

.hotDealCardMonthPriceDiv>p {
  font-size: 14px;
  font-weight: 500;
  color: #111;
  margin: 0;
}

.hotDealCardMonthPriceDiv>.hotDealCardMonthPriceTitle {
  color: #767676;
}

.hotDealCardMonthPriceDiv>.hotDealCardMonthPrice {
  position: relative;
  top: 2px;
}

.hotDealCardMonthPriceDiv>.hotDealCardMonthPrice>span {
  font-size: 20px;
  font-weight: 600;
  color: #111;
}

.hotDealCardMonthPriceDiv>.hotDealCardMonthPricePercent {
  margin-left: auto;
  margin-right: 3px;
}

.hotDealCardMonthPriceDiv>.hotDealCardMonthPricePercent>span {
  color: red;
}

.infoPaddingDiv {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.infoPaddingDiv>span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}

.infoPaddingDiv>span>p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  background-color: #f0f2f4;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  border-radius: 12px;
}

.hotDealCard > button {
  display: block;
  width: 95%;
  height: 40px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0064FF;
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.hotDealCard > button:hover {
  background-color: blue;
}



.moveButton1 {
  width: 44px;
  align-items: center;
  height: 44px;
  background-color: #9FA5AB;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 900;
  font-weight: 600;
  font-size: 20px;
  opacity: 80%;
  transition: all 0.5s ease;
}


.moveButton {
  position: absolute;
  left: 0px;
  width: 40px;
  height: 44px;
  background-color: #9FA5AB;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 900;
  font-weight: 600;
  font-size: 20px;
  padding: 0 10px 0 0;
  opacity: 80%;
  transition: all 0.5s ease;
}

.moveButton.right {
  left: auto;
  right: 0px;
  z-index: 900;
  font-weight: 600;
  font-size: 20px;
  padding: 0 0 0 10px;
}



.quickDealSection {
  position: relative;
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
}

.quickDealSection>h1 {
  font-size: 40px;
  font-weight: 400;
  margin: 0;
  margin-top: 120px;
  margin-bottom: 40px;
}

.quickDealSection>h1>span {
  font-weight: 600;
}

.quickDealCard {
  position: relative;
  min-width: 290px;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #f5f5f5;
}

.quickDealCard:hover {
  border: 1px solid #0064FF;
}

.quickDealCard > div > img {
  width: 110px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 50;
}

.quickDealCardOptionDiv {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0px;
}

.quickDealCardTitle1 {
  font-size: 14px;
  font-weight: 400;
  color: #767676;
  margin: 0;
  margin-bottom: 12px;
}

.quickDealCardInfoDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}

.quickDealCardInfo1 {
  color: #111;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.quickDealCardInfo2 {
  color: #111;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: auto;
  white-space: pre-wrap;
}

.quickDealOptionPopupDiv {
  width: 100%;
  height: 210px;
  position: absolute;
  top: 0;
  background-color: #f5f5f5;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 60;
}

/* 스크롤바 전체 */
.quickDealOptionPopupDiv::-webkit-scrollbar {
  width: 10px; /* 너비 */
}

/* 스크롤바 트랙 */
.quickDealOptionPopupDiv::-webkit-scrollbar-track {
  background: #00ff0000; /* 배경색 */
}

/* 스크롤바 핸들 */
.quickDealOptionPopupDiv::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* 색상 */
  border: 2px solid #f0f0f0; /* 핸들 주위의 여백 */
}

/* 스크롤바 핸들 호버 시 */
.quickDealOptionPopupDiv::-webkit-scrollbar-thumb:hover {
  background-color: #b4b4b4; /* 호버 시 색상 */
}

.quickDealOptionPopupDiv > p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #767676;
  white-space: pre-wrap;
}

.quickDealOptionPopupDiv > p:nth-of-type(1) {
  margin: 0;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
  color: #bbb;
}



.quickDealCardInfoMore {
  min-width: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #0064FF;
  margin: 0;
  margin-left: 5px;
}

.quickDealCardInfoDiv .quickDealCardInfo1 {
  max-width: 180px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.quickDealCardBorder {
  width: 90%;
  border-bottom: 1px solid #EDEDED;
  margin-top: 10px;
  margin-bottom: 16px;
}


.quickDealCard > button {
  display: block;
  width: 95%;
  height: 40px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0064FF;
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.quickDealCard > button:hover {
  background-color: blue;
}


.eventBannerImage {
  display: block;
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
}

.eventBannerImage>img {
  width: 100%;
  margin-top: 106px;
}

.eventSection {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
}

.eventSection>h1 {
  font-size: 40px;
  font-weight: 400;
  color: #111;
  margin: 0;
  margin-bottom: 40px;
}

.eventSection>h1>span {
  font-weight: 600;
}

.eventListDiv {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: row;
  gap: 40px;
}

.eventCard {
  width: 290px;
  height: 370px;
  position: relative;
  display: flex;
  margin: auto;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #DBDBDB;
}

.eventCardTitle {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.eventCardTitle h2 {
  margin-bottom: 8px;
}


.eventCard:hover {
  transform: scale(1.02);
}

.eventCard>img {
  display: flex;
  width: 100%;
  margin: auto;
}

.eventCard>div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.03));
}



.eventCard>div>span {
  width: 85%;
  height: 93%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.eventCard>div>span>p {
  color: white;
  margin: 0;
}

.eventCardImg {
  max-width: 100%;
  transition: all 0.3s ease;
}


.reviewSection {
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
  position: relative;
  margin-top: 120px;
  margin-bottom: 120px;
  overflow: hidden;
}

.reviewSection>h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 400;
  color: #111;
  margin-bottom: 40px;
}

.reviewSection>h1>span {
  font-weight: 600;
}

.reviewSection>div {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.reviewCardDiv {
  gap: 40px;
  transition: all 0.3s ease;
}

.reviewCard {
  min-width: 400px;
  max-width: 400px;
  height: 584px;
  background-color: white;
  border: 1px solid #ededed;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.5s ease;
  cursor: pointer;
}

.reviewCard:hover {
  border: 1px solid #0064FF;
}

.reviewCard>span>img {
  width: 100%;
  height: 308px;
  transition: all 0.5s ease;
}

.reviewCard>div {
  margin: 20px;
  text-align: left;
}

.reviewCardStarDiv {
  height: 31px;
}



.reviewCard>div>h2 {
  margin: 0;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 120%;
}

.reviewCard>div>span:nth-of-type(1) {
  display: inline-block;
  width: 100%;
  min-height: 80px;
  overflow: hidden;
}

.reviewCard>div>span:nth-of-type(1)>p {
  margin: 0;
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.reviewCard>div>span:nth-of-type(2) {
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
}

.reviewCard>div>span:nth-of-type(3) {
  display: flex;
  flex-direction: row;
}

.reviewCard>div>span:nth-of-type(3)>p:nth-of-type(1) {
  font-size: 16px;
  font-weight: 500;
  color: #111;
  margin: 0;
}

.reviewCard>div>span:nth-of-type(3)>p:nth-of-type(2) {
  margin: 0;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
  color: #767676;
  align-self: flex-end;
}

.reviewCard>div>span:nth-of-type(4) {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  margin-bottom: 40px;
  align-items: center;
}

.reviewCard>div>span:nth-of-type(4)>p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #767676;
}

.reviewCard>div>span:nth-of-type(4)>div {
  margin-left: auto;
}

.moreBtnA {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  cursor: pointer;
  width: 210px;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #BBB;
  text-decoration: none;
}

.moreBtnA>span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.moreBtnA>span>p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #767676;
}






.popularSection {
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
  margin-top: 120px;
}

.popularSection>h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 400;
  color: #111;
}

.popularSection>h1>span {
  font-weight: 600;
}

.popularSection>span {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 40px;
}

.popularSection>span>p {
  width: 85px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  color: #767676;
  border: 1px solid #bbb;
  margin: 0;
  cursor: pointer;
}

.popularSection>span>p.selected {
  color: white;
  background-color: #0064FF;
}

.popularSection>div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}
body {
    
}

.bannerSection {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.bannerSection>img {
    cursor: pointer;
}

.hotDealTitleSection {
    min-width: 1280px;
    max-width: 1280px;
    height: auto;
    margin: auto;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 40px;
    display: block;
}

.hotDealTitleSection>h1 {
    max-width: 145px;
    margin: 0;
    font-size: 40px;
    font-weight: 400;
    color: #111;
    margin-bottom: 12px;
}

.hotDealTitleSection>h1>span {
    font-weight: 600;
}

.hotDealTitleSection>p {
    max-width: 275px;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: #767676;
}


.carListSection {
    min-width: 1280px;
    max-width: 1280px;
    margin: auto;
    margin-top: 20px;
}

.carListSection>div {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 200px;
}
.admin_app-container {
    min-width: 1280px;
    display: flex;
    height: calc(100vh - 80px);
}

.admin_app-container > .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4;
    overflow-y: scroll;
}


.admin_headerSection {
    width: 100%;
    height: 80px;
    position: relative;
}

.admin_header {
    position: fixed;
    width: 100vw;
    min-width: 1280px;
    height: 80px;
    background-color: #0064FF;
    box-sizing: border-box;
    border-bottom: 1px solid #dedede;
    display: flex;
    align-items: center;
}

.admin_header > img {
    width: 150px;
    margin-left: 30px;
    cursor: pointer;
}

.admin_header > span {
    margin-left: auto;
    margin-right: 30px;
    margin-top: 10px;
    cursor: pointer;
}
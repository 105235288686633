#chat-channel-button{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2000;
}

@media (min-width: 768px) { /* 웹 브라우저 (태블릿 이상의 크기) */
    #chat-channel-button img {
        width: 150px;
    }
}

.ad-content {
    display: flex;
    flex-direction: column; /* 세로 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
    justify-content: center; /* 세로 가운데 정렬 */
    text-align: left; /* 텍스트 왼쪽 정렬 */
    margin-right: 20px;
}

.ad-warning {
    font-size: 14px; /* 작은 글씨 크기 */
    color: darkgreen; /* 강조 색상 */
    font-weight: bold; /* 강조 */
    margin-bottom: 5px; /* 아래 여백 */
}

.ad-contact {
    font-size: 18px; /* 큰 글씨 크기 */
    font-weight: bold;
}

.phone-icon {
    margin-right: 5px; /* 아이콘과 번호 간격 */
}

.gnb-social-container {
    position: relative;
    min-width: 1280px;
    max-width: 1280px;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;    
    justify-content: flex-end;
    padding: 10px 20px 0;
    background-color: white;
}

.gnb-phone {
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
    color: #333;
}

.gnb-icons {
    display: flex;
    gap: 15px;
}

.gnb-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.gnb-icon:hover {
    transform: scale(1.2);
    opacity: 0.8;
}

/* App.css 또는 PopupWindow 관련 CSS 파일에 추가 */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 검정 */
    z-index: 1700; /* 팝업 아래, 다른 요소 위 */
  }
  
  .popup {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1701; /* overlay 위에 나타남 */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px #0000004d;
    max-width: 400px;
    overflow: hidden;   
    width: 90%;
  }
  
  
  /* 버튼 컨테이너 스타일 */
  .popup-button-container {
    display: flex;
    justify-content: space-between;
    padding: 1px;
    background: #f1f1f1;
  }
  
  /* 버튼 스타일 */
  .popup-button {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .advertisement-img {
    width: 100%;
    border-radius: 10px;
    cursor: pointer; /* 마우스 포인터 표시 */
  }

  /* 모바일 화면용 팝업 스타일 */
@media (max-width: 768px) {
    .popup {
        width: 65%; /* 모바일에서는 더 작은 너비 */
        height: auto; /* 기본적으로 높이는 내용에 맞게 */
        max-height: 50%; /* 최대 높이 제한 */
        top: 50%; /* 중앙 정렬 */
        transform: translate(-50%, -50%); /* 팝업 위치 조정 */
        overflow-y: auto; /* 내용이 많을 경우 스크롤 활성화 */
    }
  }
  
.reviewMoreSection {
    min-width: 1280px;
    max-width: 1280px;
    margin: auto;
    margin-top: 180px;
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
}

.reviewMoreSection > img {
    width: 661px;
    height: 464px;
    border-radius: 12px;
}

.reviewMoreSection > div {
    margin-left: 60px;
    max-height: 464px;
}

.reviewMoreSection > div > h1 {
    font-size: 40px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin-bottom: 20px;
}

.reviewMoreSection > div > h4 {
    white-space: pre;
    font-size: 20px;
    font-weight: 500;
    color: #111;
    margin: 0;
    margin-bottom: 12px;
}

.reviewMoreSection > div > h4 > span {
    font-size: 14px;
    font-weight: 400;
    color: #767676;
}

.reviewMoreSection > div > span {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reviewMoreSection > div > span > p {
    font-size: 18px;
    font-weight: 400;
    color: #767676;
    margin: 0;
    margin-right: 10px;
}

.reviewMoreSection > div > p {
    width: 623px;
    font-size: 18px;
    font-weight: 500;
    color: #767676;
    line-height: 25px;
}




.reviewMoreListSection {
    min-width: 1280px;
    max-width: 1280px;
    max-height: 657px;
    margin: auto;
    overflow: hidden;
}

.reviewMoreListSection > h1 {
    width: 100px;
    font-size: 28px;
    font-weight: 600;
    color: #111;
    margin: 0;
    margin: auto;
    margin-bottom: 40px;
    white-space: nowrap;
}

.reviewMoreListDiv {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
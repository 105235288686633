body {
    
}

.bannerSection {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerSection > img {
    cursor: pointer;
}

.categorySection > h1 {
    font-size: 40px;
    font-weight: 400;
    color: #111;
}

.categorySection > h1 > span {
    font-weight: 600;
}

.categorySection > p {
    font-size: 16px;
    font-weight: 500;
    color: #767676;
}

.carListSection {
    width: 95%;
    margin: auto;
}

.carListSection > h2 {
    font-size: 28px;
    font-weight: 600;
    color: #111;
}

.carListSection > h2 > span {
    color: #0064FF;
}

.quickCarListSection > h2 > span {
    color: #0064FF;
}

.partnerSection {
    min-width: 1280px;
    max-width: 1280px;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 200px;
  }
  
  .partnerSection>h1 {
    width: 100%;
    font-size: 40px;
    font-weight: 400;
    color: #111;
    margin-bottom: 40px;
  }
  
  .partnerSection>h1>span {
    font-weight: 600;
  }
  
  .partnerSection>span:nth-of-type(1) {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  
  .partnerSection>span:nth-of-type(1)>span {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: all 0.5s ease; /* 애니메이션 효과 추가 */
    margin-bottom: 20px;
  }
  
  .partnerSection>span:nth-of-type(1)>span>img {
    width: calc(20% - 50px);
    margin: 10px 25px;
  }
  
  .partnerSection>span:nth-of-type(2) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  .partnerSection > span:nth-of-type(2) > button {
    width: 40px;
    height: 40px;
    color: #000000;
    font-weight: 600;
    background-color: transparent;
    border: 1px solid #DBDBDB;
    border-radius: 100px;
    cursor: pointer;
  }
  
  
  













  .mobile_partnerSection {
    width: 90%;
    margin: auto;
    margin-top: 120px;
    margin-bottom: 200px;
  }

  .mobile_partnerSection>h1 {
    width: 100%;
    font-size: 40px;
    font-weight: 400;
    color: #111;
    margin-bottom: 40px;
  }
  
  .mobile_partnerSection>h1>span {
    font-weight: 600;
  }
  
  .mobile_partnerSection>span:nth-of-type(1) {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  
  .mobile_partnerSection>span:nth-of-type(1)>span {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: all 0.5s ease; /* 애니메이션 효과 추가 */
    margin-bottom: 20px;
  }
  
  .mobile_partnerSection>span:nth-of-type(1)>span>img {
    width: calc(25% - 20px);
    margin: 10px 10px;
  }
  
  .mobile_partnerSection>span:nth-of-type(2) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  .mobile_partnerSection > span:nth-of-type(2) > button {
    width: 40px;
    height: 40px;
    color: #000000;
    font-weight: 600;
    background-color: transparent;
    border: 1px solid #DBDBDB;
    border-radius: 100px;
    cursor: pointer;
  }
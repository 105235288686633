.mobile_footer_section {
    width: 100%;
    height: 182px;
    background-color: #3D3D3D;
    overflow: hidden;
}


.mobile_footer_useInfoDiv {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #767676;
    display: flex;
    align-items: center;
}

.mobile_footer_useInfoDiv > span {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.mobile_footer_useInfoDiv > span > p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    color: #FFFFFF;
}

.mobile_footer_useInfoDiv > span > p:nth-of-type(1) {
    cursor: pointer;
}

.mobile_footer_useInfoDiv > span > p:nth-of-type(3) {
    cursor: pointer;
}


.mobile_footer_infoDiv {
    width: 90%;
    margin: auto;
    height: 92px;
}

.mobile_footer_infoDiv > p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    color: #dbdbdb;
    white-space: pre-wrap;
}

.mobile_footer_infoDiv > p:nth-of-type(1) {
    margin: 8px 0px;
    color: #FFFFFF;
}

.mobile_footer_infoDiv > p:nth-of-type(2) {
    margin-bottom: 2px;
}

.mobile_footer_infoDiv > p:nth-of-type(3) {
    margin-bottom: 8px;
}
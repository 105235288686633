.mobile_GNB_section {
    padding-top: env(safe-area-inset-top, 20px); /* 기본값을 20px로 설정 */
    padding-bottom: env(safe-area-inset-bottom, 20px);
    padding-left: env(safe-area-inset-left, 20px);
    padding-right: env(safe-area-inset-right, 20px);
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 1px solid #dbdbdb;
}

.mobile_GNB_section > div:nth-of-type(1) {
    width: 90%;
    height: 45px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.mobile_GNB_section > div:nth-of-type(1) > img {
    width: 113px;
    height: 25px;
    cursor: pointer;
}

.mobile_GNB_section > div:nth-of-type(1) > span {
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
}


.mobile_GNB_section > div:nth-of-type(2) {
    width: 90%;
    height: 45px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE와 Edge */
    scrollbar-width: none;  /* Firefox */
}

/* 특정 요소에만 스크롤바 숨기기 */
.mobile_GNB_section > div:nth-of-type(2)::-webkit-scrollbar {
    display: none;
}

.mobile_GNB_section > div:nth-of-type(2) > span {
    display: flex;
    flex-direction: row;
    white-space: nowrap; /* 줄바꿈 없이 한 줄로 표시 */
    gap: 16px;
}

.mobile_GNB_section > div:nth-of-type(2) > span > p {
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
}
